// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Img from 'gatsby-image'

import Gallery from '@bodhi-project/components/lib/gatsby/Gallery'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import K12SchoolPageWrapper from '../components/wrappers/K12SchoolPageWrapper'
import seoHelper from '../methods/seoHelper'

// import Link from '../components/Link'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageData = {
  pageTitle: 'Core Values | Learning Wings Education System',
  nakedPageSlug: 'core-values',
  pageAbstract:
    'Learning Wings Education Systems is the only authorized centre (IN364) in Punjab for conducting Qualifications for Cambridge Assessment English, a department of the University of Cambridge and part of Cambridge Assessment Group, which provides world’s leading range of qualifications for learners and teachers for English.',
}

const seoData = seoHelper(pageData)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    mobileLogo: file(relativePath: { eq: "logos/mobile-logo.png" }) {
      ...max300
    }
    desktopLogo: file(relativePath: { eq: "logos/desktop-logo.png" }) {
      ...max300
    }
    logo: file(relativePath: { eq: "logos/logo-square.png" }) {
      ...max300
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = props => (
  <K12SchoolPageWrapper className="page" seoData={seoData} {...props}>
    <div className="small-default-container">
      <h1 class="shadow" data-shadow="Core values">
        Core values
      </h1>
      <div style={{ maxWidth: '50rem' }}>
        <p>
          <strong>Rooted in traditions, looking ever onwards</strong>
        </p>
        <p>
          At Cambridge International Schools, the goal is to nurture the dreams
          of tender hearts. Every child is equipped with the confidence to
          pursue the dreams and realize them. In this world of turmoil and
          unrest they learn to pave their own path to success.
        </p>
        <h2 className="mask-h4">Academic excellence</h2>
        <p>
          Our challenging, broad-based academic program, which promotes critical
          thinking and creativity, prepares students for success in university
          and in life.
        </p>
        <h2 className="mask-h4">Continuously onward</h2>
        <p>
          We foster ethical and spiritual growth in a community founded upon
          integrity, mutual respect, environmental stewardship and service to
          others. Athletics, the arts, outdoor education and community service
          lead to the development of healthy, self-confident individuals by
          encouraging students to participate, to strive for excellence, and to
          develop strong leadership skills.
        </p>
        <h2 className="mask-h4">Global citizenship</h2>
        <p>
          With international teaching standards and holistic development we
          prepare our students for global exposure.
        </p>
        <h2 className="mask-h4">Individual integrity</h2>
        <p>
          Without individual integrity no leader can succeed. Here, in Learning
          Wings Education System, we believe that at the individual level
          integrity more than ethics, it's more about the character. It is those
          characteristics of an individual that are consistently considerate,
          compassionate, transparent, honest, and ethical. Our students are
          reliable, the one we can count on to do consistently what is right,
          and they are defenders of what is fair, just and acceptable.
        </p>
        <h2 className="mask-h4">Committed to serve</h2>
        <p>
          We promote a peaceful, caring and safe community through selfless
          service. All Cambridge Schools are committed for service to community.
        </p>
      </div>
    </div>
  </K12SchoolPageWrapper>
)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default Page
